import { endpoints } from 'services/endpoints'
import { HttpClient } from 'services/http-client'

import {
  AuthenticationResponse,
  GeneratePinResponse,
  VerifyLoginResponse
} from './AuthenticationServiceTypes'

class AuthenticationService extends HttpClient {
  public constructor() {
    super(endpoints.remote.v1.authorizer)
  }

  /**
   * Verify if the user's email exists and return its status
   * @param password string
   */
  public verifyEmail = (email: string) => {
    const reqBody = {
      login: email
    }

    console.log(endpoints.remote.v1.authorizer)

    return this.instance.post<VerifyLoginResponse>('/login', reqBody)
  }

  /**
   * Get user's token
   */
  public authenticateUser = (uuid: string, password: string) => {
    const reqBody = {
      password
    }

    return this.instance.post<AuthenticationResponse>(
      `/login/${uuid}/authenticate`,
      reqBody
    )
  }

  /**
   * Generate Auth PIN
   */
  public generatePin = (uuid: string) => {
    return this.instance.post<GeneratePinResponse>(`/login/${uuid}/pin`)
  }

  /**
   * Validate Auth PIN
   */
  public validatePin = (uuid: string, uuidPin: string, pin: string) => {
    const reqBody = {
      pin
    }

    return this.instance.put(`/login/${uuid}/pin/${uuidPin}/valid`, reqBody)
  }

  /**
   * Change user's password in his first access flow
   */
  public updateFirstAccessPassword = (
    uuid: string,
    uuidPin: string,
    password: string
  ) => {
    const reqBody = {
      password,
      uuid_pin: uuidPin
    }

    return this.instance.put(`/login/${uuid}/password`, reqBody)
  }
}

export default new AuthenticationService()
